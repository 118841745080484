<template>
  <div>
    <!-- 查询条件 -->
    <el-form
      v-show="currentInfo != 2"
      class="diverForm"
      ref="driverSearch"
      inline
      :model="driverSearch"
      :key="1"
    >
      <el-form-item>
        <el-input
          style="width: 260px"
          :placeholder="
            currentInfo == 1
              ? '请输入司机姓名/手机号'
              : '请输入收款人姓名/手机号'
          "
          v-model="driverSearch.name"
          clearable
        ></el-input>
      </el-form-item>
      <el-form-item
        label="司机归属:"
        style="margin-left: 16px;"
        v-if="issupplier"
      >
        <el-select
          v-model="driverSearch.driverType"
          placeholder="请选择司机归属"
          @change="(driverPagination.page = 1), getDriverList()"
        >
          <el-option label="自己名下" value="自己名下"></el-option>
          <el-option label="货主名下" value="货主名下"></el-option>
        </el-select>
      </el-form-item>

      <el-button
        icon="el-icon-search"
        @click="(driverPagination.page = 1), getDriverList()"
        type="primary"
        >搜索</el-button
      >
      <el-button
        icon="el-icon-delete"
        @click="clearInput"
        style="margin-left:10px;"
        >清除</el-button
      >
    </el-form>

    <!-- 面包屑 -->
    <el-breadcrumb separator="/" v-show="currentInfo == 2">
      <el-breadcrumb-item>指派司机</el-breadcrumb-item>
      <el-breadcrumb-item>{{ driverInfo.DriverName }}</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="line"></div>

    <!-- 选择司机 及 车辆 -->
    <el-col :span="24" class="appoint-btnBox">
      <el-col :span="7" style="max-height: 640px;overflow-y: auto;">
        <div
          class="diverImg"
          v-for="(item, index) in dataComputed"
          :key="index"
        >
          <el-image
            :src="item.src ? item.src : previewImg"
            :preview-src-list="item.src ? [item.src] : []"
            style="width: 280px;height:172px;"
            :fit="item.src ? 'scale-down' : ''"
          ></el-image>
          <p style="text-align: center;margin: 8px 0 16px;">
            {{ item.name }}
          </p>
        </div>
      </el-col>
      <el-col
        :span="17"
        style="padding-left: 16px;box-sizing: border-box;max-height: 640px;overflow-y: auto;"
      >
        <!-- 司机 - 表格信息 -->
        <el-table
          v-show="currentInfo == 1 || currentInfo == 3"
          ref="driverListTable"
          :data="driverPagination.list"
          v-loading="driverPagination.loading"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          :row-class-name="tableRowClassName"
          key="1"
          @current-change="handleCurrentChangeDriver"
        >
          <el-table-column
            align="center"
            prop="selected"
            :label="currentInfo == 1 ? '选择司机' : '选择收款人'"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-radio
                v-model="currentSelectedDriverIndex"
                :label="scope.row.index"
                @change.native="currentDriverSelectedChange(scope.row.index)"
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="index"
            label="序号"
            width="80"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="EnterpriseFullName"
            label="企业名称"
            :width="currentInfo == 1 ? 240 : 190"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="DriverName"
            label="姓名"
            width="110"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="Phone"
            label="手机号"
            width="150"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleCarNumber"
            label="关联车辆"
            width="100"
            v-if="currentInfo == 1"
          >
            <template slot-scope="scope">
              <span
                :style="[
                  { color: '#0F5FFF' },
                  { cursor: scope.row.VehicleCarNumber ? 'pointer' : '' },
                  {
                    'text-decoration': scope.row.VehicleCarNumber
                      ? 'underline'
                      : '',
                  },
                ]"
                @click="
                  scope.row.VehicleCarNumber
                    ? driverVehicle(scope.row.UserID)
                    : ''
                "
              >
                {{
                  scope.row.VehicleCarNumber
                    ? scope.row.VehicleCarNumber.split(",").length
                    : 0
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="VerifyFlag"
            label="电子银行开通状态"
            v-else
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              {{ scope.row.VerifyFlag == "已开立" ? "是" : "否" }}
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="IdentityType"
            label="角色"
            width="150"
            v-if="currentInfo != 1"
            show-overflow-tooltip
          ></el-table-column>
        </el-table>

        <!-- 司机 - 分页 -->
        <el-pagination
          background
          v-show="currentInfo != 2"
          style="margin-top: 16px;"
          @current-change="driverPaginationChange"
          :current-page.sync="driverPagination.page"
          :page-size="driverPagination.pagesize"
          :total="driverPagination.total"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>

        <!-- 车辆 - 表格信息 -->
        <el-table
          v-show="currentInfo == 2"
          ref="driverListTable"
          :data="vehiclePagination.list"
          v-loading="vehiclePagination.loading"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
          :row-class-name="tableRowClassName"
          key="2"
          @current-change="handleCurrentChangeVehicle"
        >
          <el-table-column
            align="center"
            prop="selected"
            label="选择车辆"
            width="100"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <el-radio
                v-model="currentSelectedVehicleIndex"
                :label="scope.row.CarNumber"
                @change.native="
                  currentvehicleSelectedChange(scope.row.CarNumber)
                "
              ></el-radio>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="index"
            label="序号"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleMaster"
            label="车辆所有人"
            width="200"
            show-overflow-tooltip
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="CarNumber"
            label="车牌"
            width="100"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="VehicleType"
            label="车辆类型"
            width="150"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="VehicleLoad"
            label="载重（吨）"
            width="100"
          >
          </el-table-column>
        </el-table>

        <!-- 车辆 - 分页 -->
        <el-pagination
          v-show="currentInfo == 2"
          background
          style="margin-top: 16px;"
          @current-change="vehicleinPaginationChange"
          :current-page.sync="vehiclePagination.page"
          :page-size="vehiclePagination.pagesize"
          :total="vehiclePagination.total"
          layout="total, prev, pager, next, jumper"
        >
        </el-pagination>

        <div class="formItem-title">
          <span></span>
          <p>基本信息</p>
        </div>

        <!-- 司机基础信息 -->
        <el-form
          v-show="currentInfo != 2"
          class="driverInfo"
          label-position="top"
          inline
          :model="driverInfo"
        >
          <el-form-item label="姓名">
            <el-input
              v-model="driverInfo.DriverName"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证号">
            <el-input
              v-model="driverInfo.DriverIDCard"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号">
            <el-input
              v-model="driverInfo.TelPhone"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="准驾类型">
            <el-input
              v-model="driverInfo.DriverLicenseType"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="驾驶证起始日期">
            <el-input
              v-model="driverInfo.DriverLicenseDateStart"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="驾驶证有效日期">
            <el-input
              v-model="driverInfo.DriverLicenseDateEnd"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="驾驶证档案号">
            <el-input
              v-model="driverInfo.DriverLicenseArchives"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="驾驶证发证机关">
            <el-input
              v-model="driverInfo.DriverLicesenAuthority"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
        </el-form>

        <!-- 车辆基础信息 -->
        <el-form
          v-show="currentInfo == 2"
          class="vehicleInfo"
          label-position="top"
          inline
          :model="vehicleInfo"
        >
          <el-form-item label="车牌号">
            <el-input
              v-model="vehicleInfo.VehicleCarNumber"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="车牌颜色">
            <el-input
              v-model="vehicleInfo.CarNumberColorName"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="车辆类型">
            <el-input
              v-model="vehicleInfo.VehicleTypeName"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="能源类型">
            <el-input
              v-model="vehicleInfo.VehiclePowerTypeName"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="所有人">
            <el-input
              v-model="vehicleInfo.VehicleMaster"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="发证机关">
            <el-input
              v-model="vehicleInfo.VehicleLicesenAuthority"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="总重(吨)">
            <el-input
              v-model="vehicleInfo.VehicleLoadWeight"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="载重(吨)">
            <el-input
              v-model="vehicleInfo.VehicleLoad"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
          <el-form-item label="车长(米)">
            <el-input
              v-model="vehicleInfo.VehicleLength"
              disabled
              style="width: 240px;"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-col>
  </div>
</template>

<script>
import { getDriverInfo } from "@/api/transport/driverManage/index";
import { getDriverDetails, getCarInfo } from "@/api/common/common.js";
import {
  driverVehicleRelation,
  GetDevCarAsync,
} from "@/api/contractLogistics/createPlan";
import { DriverDeatils } from "@/api/transport/driverManage/add.js";

export default {
  data() {
    return {
      currentInfo: 1, // 1为司机页, 2为车辆页, 3为代收人
      issupplier: false, // 服务商角色需要查询司机归属
      driverInfo: {}, // 司机信息
      previewImg: require("@/assets/image/preview.png"),
      driverInfoImg: [
        // 司机相关图片
        { index: 1, name: "身份证（正面）", value: "DriverCardOnURL", src: "" },
        {
          index: 2,
          name: "身份证（反面）",
          value: "DriverCardBackURL",
          src: "",
        },
        {
          index: 3,
          name: "驾驶证（正面）",
          value: "DriverLicenseOnURL",
          src: "",
        },
        {
          index: 4,
          name: "驾驶证（反面）",
          value: "DriverLicenseBackURL",
          src: "",
        },
        {
          index: 5,
          name: "道路运输经营许可证",
          value: "RTQCertificateURL",
          src: "",
        },
        { index: 5, name: "银行卡", value: "BankCardURL", src: "" },
        { index: 5, name: "人车合影", value: "DriverPhotoURL", src: "" },
        { index: 5, name: "手持身份证照", value: "DriverIDCardPhoto", src: "" },
      ],
      vehicleInfo: {}, // 关联车辆
      vehicleInfoImg: [
        {
          index: 1,
          name: "行驶证（正页）",
          value: "VehicleLicenseFrontPageURL",
          src: "",
        },
        {
          index: 2,
          name: "行驶证副页（正面）",
          value: "VehicleLicenseSubPageOnURL",
          src: "",
        },
        {
          index: 3,
          name: "行驶证副页（反面）",
          value: "VehicleLicenseSubPageBackURL",
          src: "",
        },
        {
          index: 4,
          name: "道路运输经营许可证",
          value: "VehicleRTPUTL",
          src: "",
        },
        { index: 5, name: "附加证照", value: "AdditionalLicenseURL", src: "" },
      ], // 车辆相关图片
      payeeInfo: {}, // 代收人信息
      driverSearch: {
        name: "",
        driverType: "自己名下",
        ownerId: "", // 司机归属0为全部
      }, // 关键字查询 司机姓名、电话
      currentSelectedDriver: {}, // 当前选择的司机信息
      currentSelectedDriverIndex: 0, // 当前选择的司机序号
      currentSelectedVehicleIndex: "", // 当前选择的车辆信息
      currentSelectedDriverId: "", // 查看司机名下车辆时, 记录司机id
      // 司机列表分页
      driverPagination: {
        list: [],
        page: 1,
        pagesize: 10,
        loading: false,
        total: 0,
      },
      // 车辆列表分页
      vehiclePagination: {
        list: [],
        page: 1,
        pagesize: 10,
        loading: false,
        total: 0,
      },
    };
  },
  created() {},
  computed: {
    // 左侧相关信息图片
    dataComputed() {
      const typeList = {
        1: this.driverInfoImg,
        2: this.vehicleInfoImg,
        3: this.driverInfoImg,
      };
      return typeList[this.currentInfo];
    },
  },
  methods: {
    // table 渐变色
    tableRowClassName({ row, rowIndex }) {
      if ((rowIndex + 1) % 2 == 0) {
        return "success-row";
      }
      return "";
    },

    // 清除搜索条件
    clearInput() {
      this.initialization();
      this.getDriverList();
    },

    // 初始化数据
    initialization() {
      this.driverSearch = {
        name: "",
        driverType: "自己名下",
        ownerId: "",
      };

      this.currentSelectedDriverIndex = 0;
      this.driverPagination.page = 1;
      this.driverInfo = {};
      this.driverInfoImg = this.driverInfoImg.map((item) => {
        item.src = "";
        return item;
      });
    },

    //获取司机列表
    getDriverList() {
      this.driverPagination.loading = true;

      let params = {
        //IdentityType: this.currentInfo == 1 ? "1" : "2", // // 1为司机, 2为代收人
        IdentityType: this.currentInfo == 1 ? "1" : "", // // 1为司机, 2为代收人
        pageIndex: this.driverPagination.page,
        pageSize: this.driverPagination.pagesize,
        type: 0,
        name: this.currentInfo != 2 ? this.driverSearch.name : "",
        // userID:
        //   this.driverSearch.driverType == "货主名下"
        //     ? this.driverSearch.ownerId
        //     : 0,
        Status: 1,
      };

      let ajaxFn =
        this.driverSearch.driverType == "货主名下"
          ? GetDevCarAsync
          : getDriverInfo;

      ajaxFn(params)
        .then((res) => {
          this.driverPagination.total = Number(res.totalRowCount);
          this.driverPagination.list = res.driver.map((item, index) => {
            item.index =
              (this.driverPagination.page - 1) *
                this.driverPagination.pagesize +
              index +
              1;

            item.selected = false;
            return item;
          });
          this.driverPagination.loading = false;
        })
        .finally(() => {
          this.driverPagination.loading = false;
        });
    },

    // 选中行 查询相关信息
    handleCurrentChangeDriver(e) {
      if (e) this.currentDriverSelectedChange(e.index);
    },

    // 选中行 查询相关信息
    handleCurrentChangeVehicle(e) {
      if (e) this.currentvehicleSelectedChange(e.CarNumber);
    },

    //处理司机表格选中变化
    currentDriverSelectedChange(e) {
      if (this.currentSelectedDriverIndex == e) return;
      this.currentSelectedDriverIndex = e;
      this.driverPagination.list.forEach((item) => {
        if (item.index != e) {
          item.selected = false;
        } else if (item.index == e) {
          this.currentSelectedDriver = item;
          this.getPersonInfo(item.UserID, item.AscriptionUserID);
        }
      });
    },

    //司机表格分页
    driverPaginationChange(e) {
      this.driverPagination.page = e;
      this.getDriverList();
    },

    // 车辆表格分页
    vehicleinPaginationChange(e) {
      this.vehiclePagination.page = e;
      this.driverVehicle(this.currentSelectedDriverId, true);
    },

    // 查询司机信息
    getPersonInfo(UserID, AscriptionUserID) {
      let ajaxFn = this.currentInfo == 1 ? getDriverDetails : DriverDeatils;

      let data = {};
      ajaxFn(
        this.currentInfo == 1 ? { UserID } : { id: UserID, AscriptionUserID }
      ).then((res) => {
        if (this.currentInfo == 1) {
          data = Object.assign(
            {},
            res.data.driver_baseInfo,
            res.data.driver_confirmInfo
          );
        } else {
          data = Object.assign({}, res.baseInfo, res.confirm);
        }

        this.driverInfo = data;

        // 存储相关照片
        this.driverInfoImg.forEach((item) => {
          item.src = this.driverInfo[item.value];
        });
      });
    },

    // 查询司机名下车牌
    driverVehicle(userID, paging) {
      this.currentInfo = 2;
      this.$emit("upChooseDriverPage", 2);

      // 如果userID上一次请求过 就跳过
      if (this.currentSelectedDriverId == userID && !paging) return;
      if (!paging) this.vehiclePagination.page = 1;
      this.currentSelectedDriverId = userID;

      this.currentSelectedVehicleIndex = "";
      this.vehicleInfo = {};
      this.vehicleInfoImg = this.vehicleInfoImg.map((item) => {
        item.src = "";
        return item;
      });

      this.vehiclePagination.loading = true;
      let params = {
        pageSize: this.vehiclePagination.pagesize,
        pageIndex: this.vehiclePagination.page,
        driverUserId: userID,
        share: this.driverSearch.driverType == "货主名下" ? true : false,
      };
      driverVehicleRelation(params)
        .then((res) => {
          this.vehiclePagination.total = Number(res.data.TotalCount);
          this.vehiclePagination.list = res.data.DataList.map((item, index) => {
            item.index =
              (this.vehiclePagination.page - 1) *
                this.vehiclePagination.pagesize +
              index +
              1;

            item.selected = false;
            return item;
          });

          this.vehiclePagination.loading = false;
        })
        .finally(() => {
          this.vehiclePagination.loading = false;
        });
    },

    // 处理车辆表格选中变化
    currentvehicleSelectedChange(e) {
      this.currentSelectedVehicleIndex = e;

      this.vehiclePagination.list.forEach((item) => {
        if (item.CarNumber != e) {
          item.selected = false;
        } else if (item.CarNumber == e) {
          // this.currentSelectedDriver = item;
          // this.getPersonInfo(item.UserID);

          getCarInfo({ CarNumber: e })
            .then((res) => {
              data = Object.assign(
                {},
                res.data.vehicle_baseInfo,
                res.data.vehicle_confirmInfo
              );
              this.vehicleInfo = data;

              // 存储相关照片
              this.vehicleInfoImg.forEach((item) => {
                item.src = this.vehicleInfo[item.value];
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table .success-row {
  background: #f3f8ff !important;
}

/deep/.el-table td,
.el-table th {
  padding: 10px 0 !important;
}
.line {
  width: 100%;
  border-bottom: 1px solid #f5f5f5;
  margin-top: 8px;
}

.appoint-btnBox {
  height: 100%;
  margin: 10px 0 5px 0;
}

.diverForm .el-form-item {
  margin-bottom: 0 !important;
}

.vehicleInfo .el-form-item {
  margin-right: 25px !important;

  &:nth-child(3n) {
    margin-right: 15px !important;
  }
}

.formItem-title {
  display: flex;
  align-items: center;
  color: #0f5fff;
  margin-top: 20px;

  span {
    display: block;
    width: 1px;
    height: 12px;
    background: #0f5fff;
    border-radius: 12px 12px 12px 12px;
    margin-right: 4px;
  }
}

/deep/ .el-radio__label {
  display: none !important;
}
</style>
